var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-layout", [
    _c("div", { staticClass: "gx-main-content-wrapper" }, [
      _c("div", { staticClass: "gx-login-container" }, [
        _c(
          "div",
          { staticClass: "gx-login-content" },
          [
            _c("div", { staticClass: "gx-login-header gx-text-center" }, [
              _c("h1", { staticClass: "gx-login-title" }, [
                _vm._v("Verify Email Account"),
              ]),
              _c("p", [
                _vm._v("Kindly click on the following button to verify"),
              ]),
            ]),
            _vm.status === "error"
              ? _c("a-alert", {
                  staticClass: "gx-mb-3",
                  attrs: { type: "error", message: _vm.message, banner: "" },
                })
              : _vm._e(),
            _c(
              "a-button",
              {
                attrs: { type: "primary", block: "", loading: _vm.isLoading },
                on: { click: _vm.emailVerify },
              },
              [_vm._v("Verify Email")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }